.App {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 650px) {
  .App {
    background-position: center center;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.footer {
  flex-shrink: 0;
  text-align: center;
  background-color: #080924;
  color: white;
  height: auto;
}
.foot {
  flex-shrink: 0;
  text-align: center;
  background-color: #060614;
  color: white;
  height: "2rem";
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.row {
  margin-right: 0px;
}
.card {
  margin: 0 auto;
  float: none;
}
