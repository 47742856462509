#register,
#login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}

form label,
form input {
  display: block;
  margin-bottom: 10px;
  width: 90%;
}

form input {
  padding: 10px;
  border: solid 1px #bdc7d8;
}

.button {
  background-color: #00bfff;
  border-color: #3ac162;
  font-weight: bold;
  padding: 12px 15px;
  color: #ffffff;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}

.sucessMsg {
  color: #6b8e23;
  margin-bottom: 10px;
}
