body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/******************HEADER*************/

header div.head {
  position: relative;
  z-index: 2;
  display: grid;
  width: 70%;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  padding-top: 12%;
  align-items: center;
}

header div.head {
  color: #222;
}

header div.head h1 {
  font-size: 5rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 0px;
  margin: 0;
  letter-spacing: 4px;
  border-right: 2px solid #111;
}

header div.head p {
  padding-left: 30px;
}

span {
  font-weight: 300;
  color: #444;
}

.navbar {
  position: fixed;
  -index: 99;
  background: #fff;
  z-index: 99;
  width: "auto";
  margin: auto;
  padding: auto;
  display: grid;
  grid-template-columns: 2fr 1fr;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
}

/***********Main**************/

main {
  position: relative;
  z-index: 3;
  overflow: hidden;
}

main section {
  width: 70%;
  padding: 90px 0;
  margin: 50px auto 150px auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}

main section h2 {
  font-size: 4.3rem;
  color: #ddd;
  text-transform: uppercase;
  line-height: 4.2rem;
  padding-right: 10px;
  border-right: 3px solid #ddd;
}

main h2 {
  font-size: 2.9rem;
  color: #ddd;
  text-transform: uppercase;
  line-height: 2.2rem;
  padding-right: 20px;
  align-items: center;
  border-right: 3px solid #ddd;
  align-content: center;
}

main section div {
  position: relative;
}

main section div p {
  padding-left: 10px;
}

/*------------- GALLERY -------------------*/

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 820px;
  width: 95%;
  margin: 60px auto 0px auto;
  -webkit-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  -moz-box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
  box-shadow: 0px 10px 20px -11px rgba(0, 0, 0, 0.9);
}

.gallery div {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  filter: grayscale(83%);
}

/* images are not compressed, remember to compress them before deployment */
.gallery div.gallery-item-one {
  background: url("../images/1.jpeg");
  background-size: 160%;
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.gallery div.gallery-item-two {
  background: url("../images/2.jpeg");
  background-size: 160%;
}
.gallery div.gallery-item-three {
  background: url("../images/3.jpeg");
  background-size: 160%;
}

.gallery div.gallery-item-four {
  background: url("../images/4.jpeg");
  background-size: 160%;
}
.gallery div.gallery-item-five {
  background: url("../images/5.jpeg");
  background-size: 160%;
}
.gallery div.gallery-item-six {
  background: url("../images/6.jpeg");
  background-size: 160%;
}
.gallery div.gallery-item-one:hover,
.gallery div.gallery-item-two:hover,
.gallery div.gallery-item-three:hover,
.gallery div.gallery-item-four:hover,
.gallery div.gallery-item-five:hover,
.gallery div.gallery-item-six:hover {
  background-size: 100%;
  filter: grayscale(0%);
}

/*------------- SERVICES -------------------*/

div.services {
  display: grid;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
}

.services div {
  text-align: center;
  background: #fff;
  width: 90%;
  border-radius: 10px;
  margin: 10px auto 50px auto;

  -webkit-box-shadow: 0px 10px 30px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 30px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 30px -9px rgba(0, 0, 0, 0.75);
}

.services p.service-icon {
  font-size: 3.4rem;
  padding: 30px 0px 30px 90px;
  margin: 0px 0px 30px 0px;
  text-align: left;
  color: rgb(0, 99, 248);
  filter: grayscale(0%);
  border-bottom: 2px solid rgb(0, 99, 248);
}
.services p.service-title {
  font-size: 3.1rem;
  padding: 0;
  margin: 0;
}

.services p:last-of-type {
  padding: 0px 90px 20px 90px;
  text-align: left;
}
.navbar {
  position: fixed;
  z-index: 99;
  background: #fff;
  z-index: 99;
  width: 100%;
  /* max-width: 1500px; */
  margin: 0 auto;
  padding: 12px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  -webkit-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
}
