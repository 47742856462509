.test {
  background-image: linear-gradient(
    to right top,
    #051937,
    #004d7a,
    #008793,
    #00bf72,
    #a8eb12
  );
  padding-bottom: 50;
}
@media all and (min-width: 960px) {
  .eventAbout {
    padding: 0 20rem 0 20rem;
  }
}

@media all and (min-width: 801px) and (max-width: 959px) {
  .eventAbout {
    padding: 0 16rem 0 16rem;
  }
}

@media all and (min-width: 769px) and (max-width: 800px) {
  .eventAbout {
    padding: 0 12rem 0 12rem;
  }
}

@media all and (min-width: 650px) and (max-width: 768px) {
  .eventAbout {
    padding: 0 10rem 0 10rem;
  }
}
@media all and (max-width: 650px) {
  .eventAbout {
    padding: 0 5rem 0 5rem;
  }
}
